import {notEmptyRegexpValidator} from '../utils';

interface DecimalValidatorParams {
  integerNumberOfDigits?: number;
  decimalNumberOfDigits?: number;
}

export const WORKUP_DECIMAL_KEY = 'workup_decimal';

export const customWorkupDecimalValidator = ({
  integerNumberOfDigits = 4,
  decimalNumberOfDigits = 2,
}: DecimalValidatorParams = {}) =>
  notEmptyRegexpValidator(
    new RegExp(`^\\d{0,${integerNumberOfDigits}}(\\.\\d{1,${decimalNumberOfDigits}})?$`),
    WORKUP_DECIMAL_KEY
  );

export const workupDecimalValidator = customWorkupDecimalValidator();
